import BaseService from "./BaseService";

export default class HomeService extends BaseService {

    postForm(data){
        return this.requester.post(
            `${this.url}/api/contato`, data
        );
    }

}
